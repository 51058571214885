var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('AppHeader'),_c('v-dialog',{attrs:{"persistent":"","height":"100%","width":_vm.$vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : _vm.$vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : _vm.$vuetify.breakpoint.name == 'md'
          ? '100vw'
          : _vm.$vuetify.breakpoint.name == 'lg'
          ? '100vw'
          : '100vw'},model:{value:(_vm.queryPopUpDialog),callback:function ($$v) {_vm.queryPopUpDialog=$$v},expression:"queryPopUpDialog"}},[_c('v-card',{attrs:{"tile":"","color":"#FFFFFF"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('QueryPopUp',{on:{"stepper":_vm.winStepper2}})],1)],1)],1)],1),_c('router-view',{key:_vm.$route.fullPath}),_c('AppFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }